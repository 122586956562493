<template>
    <tr v-bind:id="row_id">
        <td class="sort-drop">
            <i class="fas fa-grip-vertical"></i>
        </td>
        <template v-for="field_meta in row_meta" v-bind:key="field_meta.variable">
            <td>
                <component v-bind:is="field_meta._component" v-bind:value="row_value[field_meta.variable]"
                    v-bind:field="field_meta" @input="update_input_value(field_meta, $event)" :key="field_meta.variable">
                </component>
            </td>
        </template>
        <td>
            <button type="button" @click="delete_row" class="btn btn-default btn-sm bg-red"><i
                    class="fas fa-trash-alt"></i></button>
        </td>
    </tr>
</template>


<script>
export default {
    props: ['id', 'row', 'value'],
    data() {
        return {
            row_id: this.id,
            row_meta: this.row,
            row_value: this.value
        };
    },
    methods: {
        delete_row: function ($event) {
            this.$emit('row_event_click', $event, 'delete_row', this.row_id);
        },
        update_input_value: function (meta, $event) {
            this.row_value[meta.variable] = ($event.temp == undefined) ? $event.target.value : $event.temp;
            $event.temp = this.row_value;
            this.$emit('row_update_value', $event);
        }
    }
};
</script>

<style scoped>
td.sort-drop {
    width: 5px;
    cursor: pointer;
}
</style>