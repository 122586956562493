<template>
  <label v-bind:for="component_id" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>
  <button type="button" class="btn btn-default bg-green float-right" title="新增" @click="add_item">
    <i class="fas fa-plus"></i></button>

  <table class="table table-striped" v-bind:id="component_id">
    <thead>
      <tr>
        <th></th>
        <template v-for="field_meta in field_metas" v-bind:key="field_meta.label">
          <th>{{ field_meta.label }}</th>
        </template>
        <th class="operate">動作</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(row) in component_value" v-bind:key="row.id">
        <ml-field-row v-bind:value="row" v-bind:row="field_metas" v-bind:id="row.id" @row_event_click="row_event"
          @row_update_value="row_update_value"></ml-field-row>
      </template>

    </tbody>
  </table>
</template>

<script>
import $ from 'jquery'
import Row from "./Row.vue"
export default {
  components: {
    'ml-field-row': Row
  },
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {

    let fields = this.field.component.properties.fields;

    return {
      component_id: 'fields-' + Math.random().toString().substring(2),
      component_meta: this.field,
      component_value: this.value,
      field_metas: fields
    }
  },
  mounted() {
    for (let key in this.field_metas) {
      this.field_metas[key]._component = this.$microlink.components[this.field_metas[key].component.type].component;
    }

    let $this = this;
    $('#' + this.component_id + ' tbody').sortable({
      handle: ".sort-drop",
      update: function (event, ui) {

        // 被更換位置的項目，在 ui 裡有記錄，其被移動到哪個位置
        // 透過這個資訊，直接在 $this.component_value 將資料進行位置的更換
        // 以完成拖曳排序的功能

        let item_id = ui.item[0].id;
        let target = ui.item[0].rowIndex - 1;
        let source = -1;
        for (let key in $this.component_value) {
          if ($this.component_value[key].id == item_id) {
            source = key;
            break;
          }
        }

        let item = $this.component_value.splice(source, 1)[0];
        $this.component_value.splice(target, 0, item);
      }
    });
  },
  methods: {
    add_item: function () {
      let new_item = {};
      for (var key in this.field_metas) {
        new_item[this.field_metas[key].variable] = '';
      }
      new_item.id = 'row-' + Math.random().toString().substring(2);
      if (this.component_value == undefined || this.component_value == '') {
        this.component_value = [];
      }
      this.component_value.push(new_item);
    },
    row_event: function ($event, event_name, id) {

      if (event_name == 'delete_row') {

        let $this = this;

        this.$microlink.confirm('是否刪除項目', '刪除後就無法再回復囉！', function (response) {
          // 確定刪除
          if (response.isConfirmed) {
            $this.$microlink.alert('刪除成功', '', 'success');
            for (let key in $this.component_value) {
              if ($this.component_value[key].id == id) {
                $this.component_value.splice(key, 1);
                break;
              }
            }

            $event.temp = $this.component_value;
            $this.$emit('input', $event);
          }
        });
      }
    },
    row_update_value: function ($event) {
      $event.temp = this.component_value;
      this.$emit('input', $event);
    }
  }
}
</script>

<style scoped>

span.tip {
  color: green;
}

th.operate {
  width: 60px;
}
</style>